<template>
  <div class="footer bg-dark text-white">
    <div class="container">
      <div class="row">
        <div class="col-6">&copy; Brood in je buurt</div>
        <div class="col-6">
          <div class="text-light" @click="openContactModal">Contact</div>
        </div>
      </div>
    </div>
    <vue-modal-2 name="contactModal" @on-close="closeContactModal" :headerOptions="contactModalHeaderOptions" noFooter>
      <div class="contactModal">
        U kan ons voor info & vragen bereiken op <a href="mailto:info@idare.be">info@idare.be</a>
      </div>
    </vue-modal-2>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data: function() {
    return {
      contactModalHeaderOptions: {
        title: 'Contact'
      }
    }
  },
  methods: {
    openContactModal() {
      this.$vm2.open("contactModal");
    },
    closeContactModal() {
      this.$vm2.close("contactModal");
    }
  }
}
</script>

<style scoped>
.footer {
  height: 15vh;
  padding: 25px 0 50px;
}

.text-light {
  cursor: pointer;
}

.contactModal {
  margin: 0 25px 25px;
}
</style>