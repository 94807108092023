<template>
  <div id="app" class="container-fluid">
    <Top/>
    <Overview/>
    <Footer/>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'jquery/dist/jquery.slim.min.js'

import Footer from './components/Footer.vue'
import Overview from './components/Overview.vue'
import Top from './components/Top.vue'

export default {
  name: 'App',
  components: {
    Footer,
    Overview,
    Top
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}

#app {
  margin: 0;
  padding: 0;
}
</style>