<template>
  <div class="top">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <img class="navbar-brand" src="../assets/logo.png"/>
        <button class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse"
             id="navbarSupportedContent">
          <ul class="nav navbar-nav ml-auto">
            <li :class="bakkersHidden && hideClass"><button class="btn btn-primary btn-bakkers" @click="clickButton($event, 0)">Bakkers</button></li>
            <li :class="broodautomatenHidden && hideClass"><button class="btn btn-primary btn-broodautomaten" @click="clickButton($event, 1)">Broodautomaten</button></li>
            <li :class="broodjeszakenHidden && hideClass"><button class="btn btn-primary btn-broodjeszaken" @click="clickButton($event, 2)">Broodjeszaken</button></li>
            <li :class="supermarktenHidden && hideClass"><button class="btn btn-primary btn-supermarkten" @click="clickButton($event, 3)">Supermarkten</button></li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import BakkersJson from "/public/bakkers.json";
import BroodautomatenJson from "/public/broodautomaten.json";
import BroodjeszakenJson from "/public/broodjeszaken.json";
import SupermarktenJson from "/public/supermarkten.json";

export default {
  name: 'Top',
  data() {
    return {
      hideClass: 'd-none'
    }
  },
  computed: {
      bakkersHidden() {
        return BakkersJson.features.length === 0;
      },
      broodautomatenHidden() {
        return BroodautomatenJson.features.length === 0;
      },
      broodjeszakenHidden() {
        return BroodjeszakenJson.features.length === 0;
      },
      supermarktenHidden() {
        return SupermarktenJson.features.length === 0;
      }
  },
  methods: {
    clickButton(event, idx) {
      document.getElementsByClassName("leaflet-control-layers-selector")[idx].click();

      const btn = event.target;

      btn.classList.toggle("btn-primary");
      btn.classList.toggle("btn-outline-primary");
    }
  }
}
</script>

<style scoped>
.top {
  background-color: #f8f9fa;
  height: 90px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

img.navbar-brand {
  max-height: 74px;
}

.btn {
  margin: 5px;
}

.btn-primary:focus,
.btn-outline-primary:focus {
  box-shadow: none;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  box-shadow: none;
  opacity: .9 !important;
}

.btn-primary.btn-bakkers,
.btn-primary.btn-bakkers:hover {
  background-color: #CB2C3F;
  border-color: #CB2C3F;
  color: #FFFFFF;
}

.btn-outline-primary.btn-bakkers,
.btn-outline-primary.btn-bakkers:hover {
  background-color: #FFFFFF;
  border-color: #CB2C3F;
  color: #CB2C3F;
}

.btn-primary.btn-broodautomaten,
.btn-primary.btn-broodautomaten:hover {
  background-color: #4698D0;
  border-color: #4698D0;
  color: #FFFFFF;
}

.btn-outline-primary.btn-broodautomaten,
.btn-outline-primary.btn-broodautomaten:hover {
  background-color: #FFFFFF;
  border-color: #4698D0;
  color: #4698D0;
}

.btn-primary.btn-broodjeszaken,
.btn-primary.btn-broodjeszaken:hover {
  background-color: #2FAC29;
  border-color: #2FAC29;
  color: #FFFFFF;
}

.btn-outline-primary.btn-broodjeszaken,
.btn-outline-primary.btn-broodjeszaken:hover {
  background-color: #FFFFFF;
  border-color: #2FAC29;
  color: #2FAC29;
}

.btn-primary.btn-supermarkten,
.btn-primary.btn-supermarkten:hover {
  background-color: #CBC52A;
  border-color: #CBC52A;
  color: #FFFFFF;
}

.btn-outline-primary.btn-supermarkten,
.btn-outline-primary.btn-supermarkten:hover {
  background-color: #FFFFFF;
  border-color: #CBC52A;
  color: #CBC52A;
}
</style>