<template>
  <div class="overview">
    <l-map
        :zoom="zoom"
        :options="mapOptions"
        style="height: 80%"
        @update:zoom="zoomUpdate"
        @ready="onReady"
    >
      <l-tile-layer :url="url"/>
    </l-map>
  </div>
</template>

<script>
import L from 'leaflet';
import {LMap, LTileLayer} from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';

import BakkersJson from "/public/bakkers.json";
import BroodautomatenJson from "/public/broodautomaten.json";
import BroodjeszakenJson from "/public/broodjeszaken.json";
import SupermarktenJson from "/public/supermarkten.json";

export default {
  name: "Overview",
  components: {
    LMap,
    LTileLayer
  },
  data() {
    return {
      zoom: 16.5,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      currentZoom: 16.5,
      mapOptions: {
        zoomSnap: 0.5
      }
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    onReady(map) {
      map.locate({
        setView: true,
        maxZoom: 16.5
      });

      const bakkers = L.layerGroup().addTo(map);
      const broodautomaten = L.layerGroup().addTo(map);
      const broodjeszaken = L.layerGroup().addTo(map);
      const supermarkten = L.layerGroup().addTo(map);

      function popupContentFormatter(defaultName) {
        return function (point) {
          let props = point.feature.properties;
          let name = props.name ? "<b>" + props.name + "</b>" : defaultName;
          let website = props.website ? "<br /><a href='" + props.website + "' target='_blank'>Website</a>" : ""
          let address = props.address ? "<br />" + props.address : ""
          let openingHours = props.openingHours ? "<div class='openingHours'>" + props.openingHours + "</div>" : "";
          return name + website + address + openingHours;
        };
      }

      function createMarker(markerColor) {
        return function (geoJsonPoint, latLng) {
          const icon = new L.Icon({
            iconUrl: "/leaflet-markers/marker-icon-2x-" + markerColor + ".png",
            shadowUrl: "/leaflet-markers/marker-shadow.png",
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });

          return L.marker(latLng,
              {
                icon: icon,
                riseOnHover: true
              });
        };
      }

      L.geoJSON(BakkersJson, {
        pointToLayer: createMarker("red")
      }).bindPopup(popupContentFormatter("Bakker")).addTo(bakkers);

      L.geoJSON(BroodautomatenJson, {
        pointToLayer: createMarker("blue")
      }).bindPopup(popupContentFormatter("Broodautomaat")).addTo(broodautomaten);

      L.geoJSON(BroodjeszakenJson, {
        pointToLayer: createMarker("green")
      }).bindPopup(popupContentFormatter("Broodjeszaak")).addTo(broodjeszaken);

      L.geoJSON(SupermarktenJson, {
        pointToLayer: createMarker("yellow")
      }).bindPopup(popupContentFormatter("Supermarkt")).addTo(supermarkten);

      L.control
          .layers(null, {
            "Bakkers": bakkers,
            "Broodautomaten": broodautomaten,
            "Broodjeszaken": broodjeszaken,
            "Supermarkten": supermarkten
          }, {
            collapsed: false
          })
          .addTo(map);
    }
  }
}
</script>

<style scoped>
.overview {
  bottom: 0;
  height: 85vh;
  position: relative;
  top: 0;
  width: 100%;
}

.leaflet-container {
  height: calc(100% - 90px) !important;
  top: 90px;
  z-index: 0;
}
</style>

<style>
.leaflet-control-layers {
  visibility: hidden !important;
}

.leaflet-control-attribution {
  display: none;
}

.openingHours {
  margin-top: 1em;
}
</style>